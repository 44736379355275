<template>
    <div class="doubleLine"></div>
</template>

<script>
export default {
    name: '',
    data() {
        return {}
    },
    methods: {
        setChart() {

            //月份
            let dataMonth=[];//["2月", "3月", "4月", "5月", "6月", "7月"];
            //发现隐患
            let dataF=[];//[0, 0, 0, 1, 2, 0];
            //整改隐患
            let dataZ=[];//[ 0, 0, 0, 2, 1, 0];

            this.$http
                .get('https://api.luojie-safe.cn/api/v2/hiddenTrouble/htUserList')
                .then(
                    response => {//successcallback

                        dataMonth=response.body.data.xAxis;
                        dataF=response.body.data.yAxisData[0].data;
                        dataZ=response.body.data.yAxisData[1].data;

                        let option = {
                            tooltip: {
                                trigger: 'axis'
                            },
                            legend: {
                                left: "11%",
                                top: "10%",
                                itemWidth: 7,
                                itemHeight: 7,

                                textStyle: {
                                    color: '#5CB1C1',
                                    fontSize: 10
                                }
                            },
                            grid: {
                                top: '12%',
                                left: '10%',
                                right: '10%',
                                bottom: '10%',
                                containLabel: false
                            },

                            xAxis: {
                                type: 'category',
                                boundaryGap: false,
                                axisLine: {
                                    symbol: ['none', 'arrow'],
                                    symbolSize: [6, 6],
                                    symbolOffset: [0, 10],
                                    lineStyle: {
                                        color: '#122C49'
                                    }
                                },
                                axisTick: {show: false},
                                axisLabel: {
                                    color: '#61B9C8',
                                    fontSize: 9
                                },
                                //data: [ "2月", "3月", "4月", "5月", "6月", "7月"]
                                data: dataMonth
                            },
                            yAxis: [
                                {
                                    type: 'value',
                                    scale: true,
                                    max: 10,
                                    min: 0,
                                    interval: 50,
                                    axisLine: {
                                        symbol: ['none', 'arrow'],
                                        symbolSize: [6, 6],
                                        lineStyle: {
                                            color: '#122C49'
                                        }
                                    },
                                    axisLabel: {
                                        color: '#61B9C8',
                                        showMaxLabel: false,
                                        fontSize: 9
                                    },
                                    name: '(个)',
                                    nameGap: -10,
                                    nameTextStyle: {
                                        color: '#61B9C8',
                                        fontSize: 9,
                                        align: 'right',
                                        padding: [0, 6, 0, 0]
                                    },
                                    splitLine: {
                                        show: false,
                                    },
                                },
                                {
                                    type: 'value',
                                    scale: true,
                                    max: 10,
                                    min: 0,
                                    axisLine: {
                                        symbol: ['none', 'arrow'],
                                        symbolSize: [6, 6],
                                        lineStyle: {
                                            color: '#122C49'
                                        }
                                    },
                                    axisLabel: {
                                        color: '#61B9C8',
                                        showMaxLabel: false,
                                        fontSize: 9
                                    },
                                    name: '(个)',
                                    nameGap: -10,
                                    nameTextStyle: {
                                        color: '#61B9C8',
                                        fontSize: 9,
                                        align: 'left',
                                        padding: [0, 0, 0, 6]
                                    },
                                    interval: 50,
                                    splitLine: {
                                        show: false,
                                    },
                                }
                            ],
                            series: [
                                {
                                    name: '隐患整改个数',
                                    type: 'line',
                                    smooth: true,
                                    symbol: 'none',
                                    lineStyle: {
                                        color: '#F39800',
                                    },
                                    itemStyle: {
                                        color: '#F39800'
                                    },
                                    //data: [ 0, 0, 0, 6, 5, 4]
                                    data: dataZ
                                },
                                {
                                    name: '隐患发现个数',
                                    yAxisIndex: 1,
                                    type: 'line',
                                    smooth: true,
                                    symbol: 'none',
                                    lineStyle: {
                                        color: '#BF232A',

                                    },
                                    itemStyle: {
                                        color: '#BF232A'
                                    },
                                    //data: [ 0, 0, 0, 7, 5, 7]
                                    data: dataF
                                },
                            ]
                        };
                        let myChart = this.$echarts(this.$el);

                        myChart.clear();
                        myChart.resize()
                        myChart.setOption(option);
                    },
                    response => {//errorcallback
                        alert("失败！");
                        console.log(JSON.stringify(response))//错误信息
                    })


        }
    },
    mounted() {
        this.setChart()
    },
}
</script>

<style lang="less" scoped>
.doubleLine {
    height: 100%;
    width: 100%;
}
</style>
